import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useAuth } from '@/stores/auth'
import useGlobal from '@/stores/global'
import { gsap } from 'gsap'

export function BetListTable({ tbodyData, allPlayers, allAmounts, allProfits, otherPlayers, otherAmounts, otherProfits }) {
  const { t, i18n } = useTranslation()
  const myUsername = useAuth((state) => state.username)
  const lang = useGlobal((state) => state.lang)

  //header
  const allPlayersRef = useRef({ number: 0 }) //人數
  const allPlayersRefDom = useRef(null)

  const allAmountsRef = useRef({ number: 0 }) //總投注金額
  const allAmountsRefDom = useRef(null)

  //tbody
  // const allProfitsRef = useRef({ number: 0 }) //開獎金額
  // const allProfitsRefDom = useRef(null)

  //tfoot
  const otherAmountsRef = useRef({ number: 0 }) // 即時投注金額
  const otherAmountsRefDom = useRef(null)
  const otherProfitsRef = useRef({ number: 0 }) // 開獎金額
  const otherProfitsRefDom = useRef(null)

  const numberCoutersMachine = (ref, dom, propsNumber, fixedNumber) => {
    gsap.to(ref, {
      duration: 2,
      number: propsNumber,
      onUpdate: () => {
        dom.innerHTML = ref.number?.toFixed(fixedNumber)
      }
    })
  }

  useEffect(() => {
    if (allPlayers == 0) {
      allPlayersRef.current = { number: 0 }
      allAmountsRef.current = { number: 0 }
      otherAmountsRef.current = { number: 0 }
      otherProfitsRef.current = { number: 0 }
    }
    numberCoutersMachine(allPlayersRef.current, allPlayersRefDom.current, allPlayers, 0)
    numberCoutersMachine(allAmountsRef.current, allAmountsRefDom.current, allAmounts, 2)
    numberCoutersMachine(otherAmountsRef.current, otherAmountsRefDom.current, otherAmounts, 2)
    numberCoutersMachine(otherProfitsRef.current, otherProfitsRefDom.current, otherProfits, 2)
  }, [allPlayers, allAmounts, otherAmounts, otherProfits])

  return (
    <>
      <section className="mt-2 w-full">
        <table className="table-bet w-full">
          <thead>
            <tr className="text-sm">
              <th colSpan={2} className="text-white">
                <span className="mr-1" ref={allPlayersRefDom}></span>
                {t('betController.user')}
              </th>
              <th colSpan={2} className="text-win-lighter">
                <h2 className="inline-block" ref={allAmountsRefDom}></h2>
              </th>
            </tr>
          </thead>
          <tbody className="w-full title">
            <tr>
              <th>{t('betController.user')}</th>
              <th className="w-[80px]">{t('betController.betAmount')}</th>
              <th className="w-[80px]">{t('betController.multiplier')}</th>
              <th className="w-[100px] leading-[14px]">
                {t('betController.profit')}
                {lang == 'vi' && (
                  <>
                    <br />
                    <span>{t('betController.payout2')}</span>
                  </>
                )}
              </th>
            </tr>
          </tbody>
          <tbody>
            {tbodyData.map((bet, index) => {
              return (
                <tr key={`betting${bet.id}`} className={clsx(bet.status == 'win' ? 'win-bg-play' : '', bet.status == 'lose' ? 'lose-bg-play' : '')}>
                  <td>
                    {bet.status === 'win' && <img className="inline-block w-[10px] mr-[5px]" src="svg/win-star.svg" />}
                    {bet.status === 'lose' && <img className="inline-block w-[10px] mr-[5px]" src="svg/lose-star.svg" />}
                    <div className={clsx('inline-block align-middle truncate w-username', bet.username == myUsername ? 'text-[#FBCC34]' : 'text-white')}>{bet.userDisplayName?.split('#')[1] ? bet.userDisplayName.split('#')[1] || bet.username : '-'}</div>
                  </td>
                  <td className="text-main w-[80px]">{bet.bettingAmount ? Number(bet.bettingAmount)?.toFixed(2) : '-'}</td>
                  <td className="text-win opacity-50 text-center">{bet?.cashedOutAt ? `${Number(bet.cashedOutAt)?.toFixed(2)}X` : '-'}</td>
                  <td className="pr-4">
                    {bet.status === null && '-'}
                    {bet.status === 'win' && <span className="win">{bet.profit && `${bet.profit?.toFixed(2)}`}</span>}
                    {bet.status === 'lose' && <span className="lose"> {bet.bettingAmount && `${Number(bet.bettingAmount)?.toFixed(2)}`}</span>}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <th>
                + {otherPlayers} {t('betController.player')}
              </th>
              <th className="text-main" ref={otherAmountsRefDom}></th>
              <th></th>
              <th>
                <span className="win" ref={otherProfitsRefDom}></span>
              </th>
            </tr>
          </tfoot>
        </table>
      </section>
    </>
  )
}

export default BetListTable

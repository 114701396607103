import { useSocket } from '@/stores'
import { SocketEvent } from '@/common/constants'

export function NoConnection() {
  // 動畫階段
  const isSocketDisonnect = useSocket((state) => state.status == SocketEvent.DISCONNECT)

  return (
    <>
      {isSocketDisonnect && (
        <section id="no-data" className="layout-chart h-inheit flex items-center justify-center bg-gray-700">
          <label className="inline-block text-center p-4">
            <h4 className="text-white text-2xl font-bold">
              Reconnect to server <i></i>
              <svg className="animate-spin inline-block ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </h4>
          </label>
        </section>
      )}
    </>
  )
}

export default NoConnection

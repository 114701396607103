import { useTranslation, Trans } from 'react-i18next'
import { useState, useEffect, useCallback } from 'react'
import useSWR from 'swr'
import { crashPoint as api } from '@/api/restful/crashpoint'
import useCrash from '@/stores/crash'
import useGlobal from '@/stores/global'
import { useAuth } from '@/stores/auth'
import { hasObject } from '@/utils/hasObject'
import { CrashPointGameStatusIsBetting } from '@/common/constants'

const defaultBet = {
  id: 0,
  planHashId: 0,
  merchant: null,
  userId: null,
  username: null,
  userDisplayName: null,
  bettingAmount: 0,
  autoCashout: null,
  bettingTime: null,
  status: null,
  profit: null
}

let defaultList = []
for (var i = 0; i < 10; i++) {
  defaultList.push(defaultBet)
}

export function MyBetsTable() {
  const { t, i18n } = useTranslation()
  const lang = useGlobal((state) => state.lang)
  const [filterList, setFilterList] = useState(defaultList)

  // Status and token
  const gameStatusType = useCrash((state) => state.gameStatus.type)
  const authToken = useAuth((state) => state.auth_token)
  const { data, mutate } = useSWR(authToken ? 'api.getBetUserList' : null, api.getBetUserList)

  useEffect(() => {
    if (CrashPointGameStatusIsBetting.includes(gameStatusType)) {
      mutate()
    }
  }, [gameStatusType])

  const addEmptyPlayers = (list, numPlayer) => {
    if (list.length >= numPlayer) return list

    const newList = list
    const needItems = numPlayer - newList.length
    for (var i = 0; i < needItems; i++) {
      newList.push(defaultBet)
    }
    return newList
  }

  useEffect(() => {
    if (!data?.data) return
    const userBetList = data.data.filter((item) => ['win', 'lose'].includes(item.status)).sort((a, b) => b.planHashId - a.planHashId)
    const result = addEmptyPlayers(userBetList, 10) //人不夠就增加假人
    setFilterList(result.slice(0, 10))
  }, [data])

  return (
    <table className="table-bet">
      <tbody className="w-full title">
        <tr>
          <th className="w-1/3">{t('betController.betAmount')}</th>
          <th className="w-1/3">{t('betController.multiplier')}</th>
          <th className="w-[100px] leading-[14px]">
            {t('betController.payout')}
            {lang == 'vi' && (
              <>
                <br />
                <span>{t('betController.payout2')}</span>
              </>
            )}
          </th>
        </tr>
      </tbody>
      <tbody>
        {hasObject(filterList) &&
          filterList.map((bet, index) => {
            return (
              <tr key={index}>
                <td>
                  <span className="text-main">{bet.bettingAmount ? Number(bet.bettingAmount)?.toFixed(2) : '-'}</span>
                </td>
                <td>{bet.status === 'win' ? <span className="win text-center">{Number(bet?.cashedOutAt)?.toFixed(2)}x</span> : <span>-</span>}</td>
                <td>
                  {bet.status === 'win' && bet.winAmount && <span className="win"> {bet.winAmount && `${bet.winAmount?.toFixed(2)}`}</span>}
                  {bet.status === 'lose' && <span>- {Number(bet.bettingAmount)?.toFixed(2)}</span>}
                  {bet.status === null && <span>-</span>}
                </td>
              </tr>
            )
          })}
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  )
}

export default MyBetsTable

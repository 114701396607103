import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { isEmpty } from 'ramda'
import { sendBetting, sendBettingByAuto, sendStopAutoBet } from '@/components/betting/logic/bettingHandles'
import { useCrash, useAuth, useGlobal, useInitial, useSocket, useOdds, setNotification, setIsNeedCheckMoneyEnoughThisRound } from '@/stores'
import { crashPoint as api } from '@/api/restful/crashpoint'
import { useBetMaxProfit } from '@/hooks'
import { Tabs, TabManual, TabAuto } from '.'
import { errorMessage } from '@/model'
import { CONSOLE_SHOW } from '@/common/constants'
import { CrashPointGameStatusIsBetting, CrashPointGameStatus, SocketEvent } from '@/common/constants'
import { hasData } from '@/utils'
import { shallow } from 'zustand/shallow'
import dayjs from 'dayjs'
import { emitAppMoneyNotEnough } from '../../core/emitApp'

const defaultValues = {
  bettingAmount: '100.00',
  out: '1.50',
  totalBets: ''
}

export function BetController() {
  // crashStore
  const crashStore = useCrash((state) => state)
  const [lastManualFormData, gameStatusType] = useCrash((state) => [state.lastManualFormData, state.gameStatus.type], shallow)
  const [setPlayerBetStatus, playerBetStatus, nowBettingType, setNowBettingType] = useCrash((state) => [state.setPlayerBetStatus, state.playerBetStatus, state.nowBettingType, state.setNowBettingType], shallow)

  const setOdd = useOdds((state) => state.setOdd)

  // 表單驗證
  const balance = useAuth((state) => state.balance) // 檢查錢包
  const schema = yup
    .object({
      bettingAmount: yup
        .number()
        .min(0.01)
        .max(balance > 50000 ? 50000 : balance)
        .required(),
      out: yup.number().positive().min(1.01).max(10000).required(),
      totalBets: yup
        .number()
        .positive()
        .nullable(true /* or false */)
        .transform((v) => (!v ? null : v))
    })
    .required()

  const hook = useForm({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })

  let {
    watch,
    setValue,
    formState: { errors, ...formState }
  } = hook

  const expectedProfit = useBetMaxProfit({ setValue, watch }) // Bet輸入超過50000修正,get預期收益

  const props = { crashStore, expectedProfit, hook }

  // 斷線時取回上次betData的form資料
  useEffect(() => {
    if (hasData(lastManualFormData)) {
      setValue('bettingAmount', lastManualFormData.bettingAmount, { shouldValidate: true })
      setValue('out', lastManualFormData.autoCashout, { shouldValidate: true })
    }
  }, [lastManualFormData])

  // 動畫階段

  // 手動下注
  const { nextRoundBetData, setNextRoundBetData } = crashStore

  // 自動下注
  const { socketId, autoBetData, setAutoBetData, totalBets, setTotalBets, isLoopAutoBet } = crashStore

  // 清除回合資料
  const { initRoundData, initRoundList } = crashStore

  // 自動投注
  const hasAutoBetId = useCrash((state) => state.hasAutoBetId)
  useEffect(() => {
    if (!hasAutoBetId) return
    if (CrashPointGameStatusIsBetting.includes(gameStatusType)) {
      // 自動投注
      autoBetloop()
    }
  }, [hasAutoBetId, gameStatusType])
  const autoBetloop = () => {
    if (!hasAutoBetId) return
    const autoPayload = {
      bettingAmount: autoBetData?.nextBettingAmount ? autoBetData.nextBettingAmount : autoBetData.bettingAmount,
      out: autoBetData?.cashedOutAt
    }
    if (balance < autoPayload.bettingAmount) {
      setNotification('error', errorMessage.noMoneyAutobetStop())
      emitAppMoneyNotEnough()
      sendStopAutoBet()
      return
    }

    if (isLoopAutoBet) {
      CONSOLE_SHOW.AUTO_BET && console.log('[Forever] Autobet')
      sendBettingByAuto({ ...autoPayload })
    }
    if (!isLoopAutoBet) {
      if (totalBets > 0) {
        CONSOLE_SHOW.AUTO_BET && console.log(`[Left ${totalBets} times] Autobet`)
        sendBettingByAuto({ ...autoPayload })
        setTotalBets(totalBets - 1)
      } else if (totalBets == 0) {
        sendStopAutoBet()
      }
    }
  }

  //* 自動投注次數計算
  useEffect(() => {
    !totalBets ? setValue('totalBets', null, { shouldValidate: false }) : setValue('totalBets', totalBets, { shouldValidate: false })
  }, [totalBets])

  //* 自動投注利率 EndResult設定
  useEffect(() => {
    if (!hasAutoBetId) return
    if (playerBetStatus == 'END_RESULT') {
      getAutoEndResultAmount()
    }
  }, [playerBetStatus])

  //* 停損停利
  const getAutoEndResultAmount = async () => {
    const apiResult = (await api.getAutobetNextBettingAmount(useCrash.getState().autoBetUuid)).data
    setValue('bettingAmount', await apiResult?.nextBettingAmount, { shouldValidate: true })
    setAutoBetData(apiResult)

    if (isEmpty(hasAutoBetId) || hasAutoBetId === null) return
    if (isEmpty(apiResult)) return

    if (apiResult?.status == 'win' || apiResult?.status == 'lose') {
      if (autoBetData.currentProfit >= apiResult.stopOnProfit) {
        CONSOLE_SHOW.AUTO_BET && console.log('機器人停利')
        sendStopAutoBet()
      } //
      else if (apiResult.currentProfit <= apiResult.stopOnLoss) {
        CONSOLE_SHOW.AUTO_BET && console.log('機器人停損')
        sendStopAutoBet()
      }
    } else if (apiResult?.status == 'stopped') {
      CONSOLE_SHOW.AUTO_BET && console.log('停損停利成功,機器人停止')
      sendStopAutoBet()
    }
  }

  // socket status
  const isWssDisonnect = useSocket((state) => state.status === SocketEvent.DISCONNECT)

  useEffect(() => {
    if (isWssDisonnect) {
      initRoundList()
      initRoundData()
      setPlayerBetStatus('')
    }
  }, [isWssDisonnect])

  // 每回合場次的更新
  useEffect(() => {
    switch (gameStatusType) {
      case CrashPointGameStatus.BETTING:
      case CrashPointGameStatus.START_BETTING:
        // 清除上回合的玩家即時資訊
        initRoundList()
        initRoundData()
        setOdd(1)

        // 手動預定訂單下注
        if (hasData(nextRoundBetData)) {
          console.log('手動預定訂單下注', dayjs(Date.now()).format('HH:mm:ss'))
          sendBetting({ ...nextRoundBetData })
          setNextRoundBetData({})
        }

        // PLAYER_BET_EVENT恢復空值
        setPlayerBetStatus('')
        break

      case CrashPointGameStatus.END_DRAWING:
        if (useCrash.getState().isNeedCheckMoneyEnoughThisRound && balance < 100) emitAppMoneyNotEnough() //本局有下注, 並且錢包只剩不足100元, emit商戶.
        if (!hasAutoBetId && isEmpty(nextRoundBetData)) setNowBettingType('') // 如果是手動解除鎖定tabs
        setIsNeedCheckMoneyEnoughThisRound(false) //清除本局有下注
        break
    }
  }, [gameStatusType])

  // 控制下注後Tabs鎖定
  const bettingTypeTab = useCrash((state) => state.bettingTypeTab)

  return (
    <>
      <form className="w-full relative" autoComplete="off">
        {nowBettingType != '' && <section id="now-betting-backdrop" className="absolute z-10 bg-black opacity-50 h-full w-full "></section>}
        <section className="bg-[#25263C] px-4 pb-4">
          <div id="custom-numpad"></div>

          <Tabs {...props} />

          {/* [MANUAL] */}
          {bettingTypeTab === 'MANUAL' && <TabManual {...props} />}

          {/* [AUTO] */}
          {bettingTypeTab === 'AUTO' && <TabAuto {...props} />}
        </section>
      </form>
    </>
  )
}

export default BetController

import { useTranslation } from 'react-i18next'
import useGlobal from '@/stores/global'
import i18n from '@/i18n'

const styleYoutubeWrapper: React.CSSProperties = {
  paddingBottom: '56%',
  position: 'relative'
}

export function Tutorial() {
  const { t } = useTranslation()

  const lang = useGlobal((state) => state.lang)
  const youtubeLink = lang === 'vi' ? 'https://www.youtube.com/embed/kFGq2vSIdHE?si=z3-mlTsipVACyMYU' : 'https://www.youtube.com/embed/p64PzBd6XJ8?si=cQ5z0wooOmtGbZil'

  return (
    <>
      <h2 className="text-white text-lg font-bold mb-2">{t('doc.tutorial.introduce')}</h2>
      <section className="px-2 mb-8">
        <article className="text-[#B0B0B0] text-[14px]">{t('doc.tutorial.description')}</article>
      </section>

      <section id="youtube-wrapper" className="mx-2 mb-8" style={styleYoutubeWrapper}>
        <div className="absolute w-full h-full top-0 bottom-0 left-0 right-0">
          <iframe className="w-full h-full" src={youtubeLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </section>

      <section className="px-2 mb-8">
        <h4 className="text-[#FBCC34]">{t('doc.tutorial.step1')}</h4>
        <small className="block ml-3 mb-[-3px]">{t('betController.betAmount')}</small>
        <img className="my-1" loading="lazy" src="images/toturial/tutorial_step1_0516.png"></img>
        <div className="text-[#B0B0B0] text-sm">{t('doc.tutorial.step1Txt')}</div>
      </section>

      <section className="px-2 mb-8">
        <h4 className="text-[#FBCC34]">{t('doc.tutorial.step2')}</h4>
        <small className="block ml-3 mb-[-3px]">{t('betController.cashoutAt')}</small>
        <img className="my-1" loading="lazy" src="images/toturial/tutorial_step2_0516.png"></img>

        <div className="text-[#B0B0B0] text-sm">{t('doc.tutorial.step2Txt')}</div>
      </section>

      <section className="px-2 mb-8">
        <h4 className="text-[#FBCC34]">{t('doc.tutorial.step3')}</h4>
        <img className="my-1" loading="lazy" src="images/toturial/tutorial_step3_0516.png"></img>
        <div className="text-[#B0B0B0] text-sm mb-2">{t('doc.tutorial.step3Txt')}</div>
        <div className="text-[#B0B0B0] text-sm">{t('doc.tutorial.step3Txt2')}</div>
      </section>

      <h2 className="text-white text-lg text-bold mb-2">{t('doc.tutorial.rule')}</h2>
      <section className="px-2 mb-8">
        <ol className="doc-ol">
          <li> {t('doc.tutorial.rule1')}</li>
          <li> {t('doc.tutorial.rule2')}</li>
          <li> {t('doc.tutorial.rule3')}</li>
          <li> {t('doc.tutorial.rule4')}</li>
          <li> {t('doc.tutorial.rule5')}</li>
          <li> {t('doc.tutorial.rule6')}</li>
        </ol>
      </section>
      <footer className="text-[14px] text-center"> Version: {process.env.NEXT_PUBLIC_RELEASE}</footer>
    </>
  )
}

export default Tutorial

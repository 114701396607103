import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { crashPoint as api } from '@/api/restful/crashpoint'
import useGlobal from '@/stores/global'
import useCrash from '@/stores/crash'
import { useAuth } from '@/stores/auth'
import { CrashPointGameStatus } from '@/common/constants'

export function RankingTable() {
  const { t, i18n } = useTranslation()
  const lang = useGlobal((state) => state.lang)

  // Status and token
  const isGameEventStatusBetting = useCrash((state) => state.gameStatus.type === CrashPointGameStatus.BETTING || state.gameStatus.type === CrashPointGameStatus.START_BETTING)
  const authToken = useAuth((state) => state.auth_token)
  const { data, mutate } = useSWR(authToken ? 'api.getBetRanking' : null, api.getBetRanking)

  useEffect(() => {
    if (isGameEventStatusBetting) {
      mutate()
    }
  }, [isGameEventStatusBetting])

  const iconsMap = ['svg/star1.svg', 'svg/star2.svg', 'svg/star3.svg', 'svg/star-normal.svg', 'svg/star-normal.svg', 'svg/star-normal.svg', 'svg/star-normal.svg', 'svg/star-normal.svg', 'svg/star-normal.svg', 'svg/star-normal.svg']

  return (
    <table className="table-bet">
      <tbody className="w-full title">
        <tr>
          <th>{t('betController.user')}</th>
          <th className="w-1/5">{t('betController.betAmount')}</th>
          <th className="w-1/5">{t('betController.multiplier')}</th>
          <th className="w-[100px] leading-[14px]">
            {t('betController.profit')}
            {lang == 'vi' && (
              <>
                <br />
                <span>{t('betController.payout2')}</span>
              </>
            )}
          </th>
        </tr>
      </tbody>
      <tbody>
        {data?.data?.map((rank, index) => {
          return (
            <tr key={index}>
              <td>
                <article className="flex flex-row items-center">
                  <div className="relative">
                    <span className="absolute w-[18px] h-[20px] font-bold text-white text-center text-[10px] top-[1px] shadow-text">{index + 1}</span>
                    <img className="mr-1 w-[18px] h-[20px]" loading="lazy" src={iconsMap[index]}></img>
                  </div>
                  <div className="text-white truncate w-username">{rank?.userDisplayName?.split('#')[1] ? rank.userDisplayName.split('#')[1] || rank.username : '-'}</div>
                </article>
              </td>
              <td>
                <span className="text-main">{Number(rank.bettingAmount)?.toFixed(2)}</span>
              </td>
              <td>
                <span className="win text-center">{Number(rank?.cashedOutAt)?.toFixed(2)}x</span>
              </td>
              <td>
                <span className="win">{Number(rank.profit)?.toFixed(2)}</span>
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  )
}

export default RankingTable

import { v4 as uuidv4 } from 'uuid'
import dynamic from 'next/dynamic'
import * as Sentry from '@sentry/nextjs'
import { useCheckReconnectData, useVisibilityChange, useInitI18n, useUserInfo } from '@/hooks'
import { useBettingSocket } from '@/api/io/useBettingSocket'

import { BetController } from '@/components/controller/BetController'
import { BetHistoryController } from '@/components/history/BetHistoryController'
import { BetListController } from '@/components/betting/BetListController'
import { ChartLoading } from '@/components/betting/ChartLoading'
import { InfoBlock } from '@/components/info/InfoBlock'
import { Records, Numpad, ModalReconnect, ModalOffline } from '@/components/'
import { useRouter } from 'next/router'
import { setTokens, useAuth } from '@/stores'
import { useEffect } from 'react'
import { span, transaction } from '@/sentry-transactions/startApp'
import { exitGame, readyGame } from '@/core/native'

import { crashPoint as api } from '@/api/restful/crashpoint'

// i18n global
import LangSelector from '@/components/lang/LangSelector'
import { Trans, useTranslation } from 'react-i18next'
import { langName } from '../i18n'
import { DemoButtons } from '@/components/ui/DemoButtons'
import { BackButtonBar } from '../components/BackButtonBar'
import { initConsole } from '@/utils/initConsole.util'
import { initBodyEventListener } from '@/common/body-event.init'
import { initKibana } from '@/common/kibana.util'
import { parseAndSaveJwt } from '@/common/jwt.util'

const RocketScreen = dynamic(() => import('@/components/betting/RocketScreen'), { ssr: false })

// initConsole()
initKibana()
initBodyEventListener()

export function CrashPointPage() {
  // check user
  const router = useRouter()
  const {
    isReady,
    query: { auth_token, refresh_token, redirect_url }
  } = router

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const auth_token = urlSearchParams.get('auth_token')
    if (auth_token) {
      parseAndSaveJwt(auth_token)
      setTokens(auth_token, refresh_token, redirect_url)

      // 進入遊戲
      setTimeout(() => {
        let traceId = uuidv4()
        Sentry.configureScope((scope) => {
          scope.setTag('trace_id', traceId)
          scope.setTag('app', 'crashpoint')
          Sentry.captureMessage('START_GAME: READY')
        })
        readyGame()
        Sentry.configureScope((scope) => {
          scope.setTag('trace_id', traceId)
          scope.setTag('app', 'crashpoint')
          Sentry.captureMessage('START_GAME: FINISH')
        })
      }, 200)
    } else {
      console.log('router', router)
      alert('router: ' + auth_token)
      alert(`token is missing. \n ${document.location.href}`)
      Sentry.captureException(new Error('token is missing.'), (scope) => {
        scope.setTag('ytg-crashpoint-error', 'token-error')
        scope.setContext('location info', {
          doc: JSON.stringify(document.location),
          win: JSON.stringify(window.location)
        })
        return scope
      })

      // 沒有token 跳出遊戲
      console.log('沒有token 跳出遊戲')
      exitGame()
    }
  }, [])

  // DEMO測試錯誤api
  // useEffect(() => {
  //   console.log('%cTEST!!!!!! ErrorCode', 'color:yellow')

  //   let data: any = {
  //     bettingAmount: 123,
  //     autoCashout: 2, //autoCashout
  //     bettingType: 2,
  //     planHashId: 1294820398032,
  //     orderTraceId: 'daydayTest392048029385235'
  //   }
  //   api.test(data)

  //   setTimeout(() => {
  //     api.test(data)
  //   }, 2000)
  // }, [])

  // == init
  useInitI18n() //多語系
  useBettingSocket()
  useUserInfo()
  useCheckReconnectData()
  useVisibilityChange()
  // ==

  useEffect(() => {
    const ts = transaction.get()
    const sp = span.get()
    sp.setStatus('page.index.rendered')
    sp.finish()
    ts.finish()
  }, [])

  return (
    <>
      {/* Demo語言列 */}
      {/* <DemoButtons>
        <LangSelector />
      </DemoButtons> */}

      <main id="crashpoint-mobile" className="flex flex-col items-center justify-center">
        <div id="audioAutoPlay" />

        {/* 斷線5次後出現提示 */}
        <ModalReconnect />

        {/* 網路斷線出現提示 */}
        <ModalOffline />

        <BackButtonBar />
        <section className="relative w-full">
          {/* Chart */}
          <section className="relative layout-chart">
            <article id="chart-info" className="h-30 w-full absolute top-0 left-0">
              {/* 使用者錢包 音效開關 */}
              <InfoBlock />
            </article>
            <article className="absolute w-full">
              <div className="flex items-center justify-center layout-chart">
                {/* 讀取中 */}
                <ChartLoading />
                {/* 火箭動畫 */}
                <RocketScreen />
              </div>
            </article>
            <article className="absolute w-full bottom-[-8px]">
              <BetHistoryController />
            </article>
          </section>
          <main>
            <BetController />
          </main>
          <main>
            <BetListController />
          </main>
          <main>
            <Records />
          </main>
          {/* 數字鍵盤 */}
          <Numpad />
        </section>
      </main>
    </>
  )
}

export default CrashPointPage

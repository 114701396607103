import { useEffect, useState } from 'react'
import useCrash from '@/stores/crash'
import { useAuth } from '@/stores/auth'
import { BetListTable } from '@/components/betting/BetListTable'
import _ from 'lodash'

const defaultBet = {
  planHashId: 0,
  merchant: null,
  userId: null,
  username: null,
  userDisplayName: null,
  bettingAmount: 0,
  autoCashout: null,
  bettingTime: null,
  status: null,
  profit: null
}
const reducerAllAmount = (cost, entry) => cost + (Number(entry.bettingAmount) || 0)
const reducerAllProfits = (cost, entry) => cost + (Number(entry.profit) || 0)

const addEmptyPlayers = (list, numPlayer) => {
  if (list.length >= numPlayer) return list

  const newList = list
  const needItems = numPlayer - newList.length
  for (var i = 0; i < needItems; i++) {
    newList.push({ id: i, ...defaultBet })
  }
  return newList
}

// 將使用者排序在最上方

export const BetListController = () => {
  //Tbody的資訊
  const [allPlayers, setAllPlayers] = useState<string | number>(0)
  const [allAmounts, setAllAmounts] = useState<string | number>(0)
  const [allProfits, setAllProfits] = useState<string | number>(0)

  const [otherPlayers, setOtherPlayers] = useState<string | number>(0)
  const [otherAmounts, setOtherAmounts] = useState<string | number>(0)
  const [otherProfits, setOtherProfits] = useState<string | number>(0)
  const [tbodyData, setTbodyData] = useState([])

  // 投注表, 停注表
  const mergeList = useCrash((state) => state.mergeList)

  useEffect(() => {
    // console.log('mergeList', mergeList)
    updateTbody(mergeList)
  }, [mergeList])

  const updateTbody = (apiList) => {
    // 下注總人數
    setAllPlayers(apiList.length)

    const userBet = apiList.filter((item) => item.username === useAuth.getState().username && ['win', 'lose', 'bet'].includes(item.status))
    const otherBetList = _.chain(apiList)
      .filter((item) => item.username !== useAuth.getState().username && ['win', 'lose', 'bet'].includes(item.status))
      .sort((a, b) => +b.bettingAmount - +a.bettingAmount)
      .value()

    const allList = [...userBet, ...otherBetList]
    const mainBets = addEmptyPlayers(allList.slice(0, 8), 8)
    const otherBets = allList.slice(8, allList.length)
    // Tbody資訊

    setTbodyData(mainBets)
    setOtherPlayers(otherBets.length)
    // 其他總金額
    setOtherAmounts(Number(otherBets.reduce(reducerAllAmount, 0))?.toFixed(2))
    // 其他總利潤
    setOtherProfits(Number(otherBets.reduce(reducerAllProfits, 0))?.toFixed(2))

    // 投注總金額
    setAllAmounts(Number(allList.reduce(reducerAllAmount, 0))?.toFixed(2))
    // 投注總利潤
    setAllProfits(Number(allList.reduce(reducerAllProfits, 0))?.toFixed(2))
  }

  return (
    <>
      <BetListTable {...{ tbodyData, allPlayers, allAmounts, allProfits, otherPlayers, otherAmounts, otherProfits }} />
    </>
  )
}

export default BetListController

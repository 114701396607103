import { useInitial, useSocket } from '@/stores'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

export function ChartLoading() {
  const isConnected = useSocket((state) => state.isConnected)
  const userClosedConnection = useSocket((state) => state.userClosedConnection) //用戶關閉了重連機制

  const [isShowCircle, setShowCircle] = useState(false) //本機控制

  const initialStore = useInitial((state) => {
    const { SocketIO } = state
    return {
      ...state,
      isDone: SocketIO
    }
  })

  useEffect(() => {
    if (userClosedConnection) {
      setShowCircle(false)
      return
    }

    if (!isConnected) {
      setShowCircle(true)
    } else if (isConnected) {
      setShowCircle(false)
    }
  }, [userClosedConnection, isConnected])

  return (
    <main id="chart-loading" className={clsx(initialStore.isDone ? 'animate__animated animate__fadeOutUp' : '', 'z-[3000] absolute ', 'flex items-center w-full h-full bg-main-100')}>
      <div className="flex flex-col items-center justify-center text-center w-full h-full">
        <img src="svg/rocket-crashpoint.svg" className="h-[80px]" />
        <div className="h-[20px]"></div>

        <svg className={clsx(isShowCircle ? 'inline-block' : 'hidden', 'absolute bottom-[70px] animate-spin h-5 w-5 text-white')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </main>
  )
}

export default ChartLoading

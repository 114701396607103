import { useState } from 'react'
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
const MyBetsTable = dynamic(() => import('./MyBetsTable'))
const RankingTable = dynamic(() => import('./RankingTable'))

export function Records() {
  const { t } = useTranslation()
  const [tabType, setTabType] = useState('My Bets')
  const [hideContent, setHideContent] = useState(true)
  const isActiveCss = 'text-[#8B99BF] border-[#8B99BF] border-b-2'
  const notActiveCss = 'text-[#5F6173] border-[#5F6173] border-b'

  const handleTriggleContent = () => {
    setHideContent(!hideContent)

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight)
    }, 200)
  }

  const handleTriggleTabs = (string) => {
    setTabType(string)
  }

  return (
    <section className="wrapper-table-bet mt-2 mb-4">
      <header className="flex flex-row justify-between py-2 pl-4">
        <h2 className="text-lg text-[500]">Crash Point</h2>
        <button className="pl-[60px] pr-4 " onClick={() => handleTriggleContent()}>
          {hideContent ? <img className="rotate-180" loading="lazy" src="svg/arrow.svg"></img> : <img loading="lazy" src="svg/arrow.svg"></img>}
        </button>
      </header>

      {!hideContent && (
        <>
          {/* TABS */}
          <article className="flex flex-row items-center">
            <button type="button" onClick={() => handleTriggleTabs('My Bets')} className={clsx('p-1 pb-3 text-sm flex-1', tabType === 'My Bets' ? isActiveCss : notActiveCss)}>
              {t('myBets')}
            </button>
            <button type="button" onClick={() => handleTriggleTabs('Leaderboard')} className={clsx('p-1  pb-3 text-sm  flex-1', tabType === 'Leaderboard' ? isActiveCss : notActiveCss)}>
              {t('leaderboard')}
            </button>
          </article>

          <section className="h-[430px]">
            {/* tables My Bets */}
            <main className={clsx(tabType === 'My Bets' ? '' : 'hidden')}>
              <MyBetsTable />
            </main>

            {/* tables Leaderborad */}
            <main className={clsx(tabType === 'Leaderboard' ? '' : 'hidden')}>
              <RankingTable />
            </main>
          </section>
        </>
      )}
    </section>
  )
}

export default Records

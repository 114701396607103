import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useCrash } from '@/stores'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { crashPoint as api } from '@/api/restful/crashpoint'
import { DrawListTable, GameRecordTable } from './'
import { Modal } from '@/components/ui'
import { Tutorial } from '@/components/'
import { CrashPointGameStatusIsFinish } from '@/common/constants'
import { GameReadyStream } from '@/common/initGameReady'

export function BetHistoryController() {
  const { t, i18n } = useTranslation()

  const gameStatusType = useCrash((state) => state.gameStatus.type)

  const [isModalShow, setIsModalShow] = useState('') // DRAWLIST, GAME, RULES
  const [gameData, setGameData] = useState({}) // Game 資料

  const { data, mutate } = useSWR('api.getDrawList', api.getDrawList)
  const drawList = data?.data
  GameReadyStream.updateHistoryList$(drawList)

  useEffect(() => {
    if (CrashPointGameStatusIsFinish.includes(gameStatusType)) {
      mutate()
    }
  }, [gameStatusType])

  // GAME_RECORD
  const handleShowGameRecord = (game) => {
    setGameData(game)
    setIsModalShow('GAME')
  }

  // DRAWLIST(History)
  const handleShowHistory = () => {
    setIsModalShow('DRAWLIST')
  }

  // TURORIAL
  const handleShowTutorial = () => {
    setIsModalShow('TURORIAL')
  }

  const handleModalClose = () => {
    setIsModalShow('')
  }

  return (
    <>
      <section id="crash-previous" className="relative flex flex-row justify-end items-center py-1 w-full">
        {/* 歷史紀錄 navbar */}
        {drawList && (
          <div className="z-[5] overflow-x-scroll whitespace-nowrap custom-scroll-transparent">
            <div id="crash-previous__buttons" className="flex flex-row-reverse items-center h-[36px]">
              {/* 陰影 */}
              <div id="shadow-left" className="absolute z-[10] left-0 top-0 text-xs w-[50px] pr-1 h-[36px]">
                <div className="w-[36px] h-[36px] bg-[#191A2B]">
                  <button className="w-full h-full" onClick={() => handleShowHistory()}>
                    <img className="mx-auto" loading="lazy" src="svg/icon-solid-chart.svg"></img>
                  </button>
                </div>
              </div>
              {/* 數字按鈕 */}
              {drawList?.length > 0 ? (
                drawList?.map((game, index) => {
                  return (
                    <button onClick={() => handleShowGameRecord(game)} className={clsx('animate__animated animate__fadeInRight text-main rounded-xl py-1 px-1 text-xs mr-1.5 h-[24px] basis-[48px]', game.crashPoint >= 2 ? 'text-[#E2C55C]' : 'text-main')} key={index}>
                      {game.crashPoint?.toFixed(2)}x
                    </button>
                  )
                })
              ) : (
                <div className="py-2 px-1 w-full">
                  <button></button>
                </div>
              )}
            </div>
          </div>
        )}
      </section>

      {/* 跳彈 DRAWLIST(drawList) 歷史紀錄 */}
      {isModalShow == 'DRAWLIST' && <DrawListTable {...{ handleModalClose, handleShowGameRecord }} />}

      {/* 跳彈 GAME_RECORD 詳細資料 */}
      {isModalShow == 'GAME' && gameData && <GameRecordTable {...{ gameData, handleModalClose, handleShowTutorial }} />}

      {/* 跳彈 TURORIAL */}
      {isModalShow == 'TURORIAL' && (
        <Modal idName="TURORIAL" zIndex="2060" title={t('doc.tutorial.tutorial')} closeCallback={handleModalClose}>
          <Tutorial />
        </Modal>
      )}
    </>
  )
}
export default BetHistoryController

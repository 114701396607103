import { useState } from 'react'
import { useAuth } from '@/stores'
import { useTranslation } from 'react-i18next'

import { Modal } from '@/components/ui/'
import { Tutorial } from '@/components/'
import { moneyFormat, isBrowser } from '@/utils/'

import dynamic from 'next/dynamic'
import { emitAppAddMoney } from '@/core/emitApp'
const InfoAudio = dynamic(() => import('@/components/info/InfoAudio'), { ssr: false })

export function InfoBlock() {
  const { t } = useTranslation()

  // 錢包
  const balance = useAuth((state) => state.balance)

  // RULES
  const [isModalShow, setIsModalShow] = useState('')

  const handlePostMsg = () => {
    try {
      isBrowser() && (window as any)?.ReactNativeWebView?.postMessage('popup:obtain-coin')
      isBrowser() && console.log('postMessage SEND!')
      isBrowser() && emitAppAddMoney()
    } catch (e) {}
  }

  return (
    <section className="h-[46px] text-white flex flex-row justify-between items-center p-4">
      <article className="w-full">
        {balance ? (
          <div>
            <img className="w-[16px] h-[16px] inline-block mr-1" loading="lazy" src="svg/coin-ic.svg"></img>
            <span className="font-bold text-[12px] ">{moneyFormat(balance, 2)}</span>
            <button
              className="p-2"
              onClick={() => {
                handlePostMsg()
              }}>
              <img className="w-[10px] h-[10px] inline-block" loading="lazy" src="svg/plus.svg"></img>
            </button>
          </div>
        ) : (
          <small>{t('nodata')}</small>
        )}
      </article>
      <InfoAudio />
      <button
        className="text-white text-center flex flex-col"
        onClick={() => {
          setIsModalShow('TURORIAL')
        }}>
        <img className="w-[22px]" src="images/info.png" />

        {/* <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35791 11.6421 0 7.5 0C3.35791 0 0 3.35791 0 7.5C0 11.6421 3.35791 15 7.5 15ZM9 4.5C9 5.32837 8.32837 6 7.5 6C6.67163 6 6 5.32837 6 4.5C6 3.67163 6.67163 3 7.5 3C8.32837 3 9 3.67163 9 4.5ZM5 7H6H8H9V11H10V12H9H6H5V11H6V8H5V7Z" fill="#71727F" />
        </svg> */}
      </button>

      {/* 跳彈 Rules */}
      {isModalShow == 'TURORIAL' && (
        <div className="absolute z-[2060] border-2 border-red-200">
          <Modal
            idName="TURORIAL"
            zIndex="2060"
            title={t('doc.tutorial.tutorial')}
            closeCallback={() => {
              setIsModalShow('')
            }}>
            <Tutorial />
          </Modal>
        </div>
      )}
    </section>
  )
}

export default InfoBlock

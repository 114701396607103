import { table } from 'console'
import io from 'socket.io-client'
import { getTableId } from '../restful/crashpoint'

export function getSocketIo(url, auth_token) {
  return io(url, {
    path: '/socket.io',
    autoConnect: true,
    reconnection: true, //手動設定
    reconnectionDelay: 1000,
    reconnectionDelayMax: 3000,
    timeout: 10000,
    extraHeaders: {
      auth_token,
      tableId: getTableId()
    }
  })
}

export default getSocketIo

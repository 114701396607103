import { PlatformEmit } from './platform/platform.util'
import { APILogger } from '@/api/APILogger'

export const initBodyEventListener = () => {
  if (typeof window !== 'undefined') {
    const body = document.getElementsByTagName('body')[0]
    body.onload = () => {
      PlatformEmit.gameVisible()
      const logger = new APILogger()
      logger.pageLoad()
    }
  }
}

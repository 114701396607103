import { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import ReactGA from 'react-ga4'
import { shallow } from 'zustand/shallow'
import { Socket } from 'socket.io-client'

import getSocketIo from './getSocketIo'
import { sendLog } from '@/api/restful/kibana'
import { useCrash, useAuth, useGlobal, useInitial, useLogStore, useSocket, setGameEvents } from '@/stores/'
import { CONSOLE_SHOW } from '@/common/constants'
import { SocketEvent } from '@/common/constants'

const WSS_CRASHPOINT = process.env.VITE_WSS_CRASHPOINT

export function useBettingSocket() {
  const clientSocket = useRef<Socket>()

  // Socket
  const setSocket = useSocket((state) => state.setSocket)
  const setSocketStatus = useSocket((state) => state.setSocketStatus)

  // Token
  const auth_token = useAuth((state) => state.auth_token)

  //現在投注形式 //autobet的時候如果手動cashedout disable取消按鈕
  const setSocketId = useCrash((state) => state.setSocketId)

  // 下注狀態 影像自動投注, END_RESULT時的form核對
  const [setPlayerBetStatus] = useCrash((state) => [state.setPlayerBetStatus], shallow)

  // 投注設定
  const [setPlayerBetList, setPlayerOutList, initRoundData, initRoundList] = useCrash((state) => [state.setPlayerBetList, state.setPlayerOutList, state.initRoundData, state.initRoundList], shallow)

  // 遊戲狀態
  const setGameStatus = useCrash((state) => state.setGameStatus)

  useEffect(() => {
    console.log('[init] useBettingSocket')
  }, [])
  useEffect(() => {
    if (auth_token) ioConnect(auth_token)

    return () => {
      console.warn('destroy useBettingSocket')
      if (clientSocket.current) clientSocket.current.disconnect()
    }
  }, [auth_token])

  const ioConnect = (auth_token) => {
    console.log('ioConnect', auth_token)
    clientSocket.current = getSocketIo(WSS_CRASHPOINT, auth_token)
    clientSocket.current.on('connect', async function () {
      console.log('%c[IO] Open connection ', 'color:skyblue', clientSocket.current.id)
      useSocket.setState({ isConnected: true })
      useSocket.getState().setReconnectTimes(false)
      setSocketStatus(SocketEvent.CONNECT)
      clientSocket.current.emit('INIT') //取得場次a

      setGameEvents('socketIOConnect')

      // 每3秒Pong一次
      // setInterval(() => {
      //   clientSocket.current.emit('PING')
      // }, 5000)

      // 連線傳GA
      ReactGA.event({
        category: 'Crashpoint',
        action: 'cp_socketIO_connect',
        label: '小遊戲Crashpoint SocketIO連線'
      })
      setSocketId(clientSocket.current.id)
    })

    clientSocket.current.on('disconnect', function (data) {
      console.log('%c[IO] Disconnect ', 'color:red', data)
      useSocket.setState({ isConnected: false })

      // 斷線傳回GA
      ReactGA.event({
        category: 'Crashpoint',
        action: 'cp_socketIO_disconnect',
        label: '小遊戲Crashpoint SocketIO斷線'
      })
      useInitial.setState({ SocketIO: false })
      console.log('使用者SocketIO斷線')
      setGameEvents('socketIO_disconnect')

      setSocketStatus(SocketEvent.DISCONNECT)
      ioReconnect()
    })

    //所有用戶投注
    clientSocket.current.on('PLAYER_BET_EVENT', async function (data) {
      // console.log(`%cPLAYER_BET_EVENT [${data.type}]`, 'color:skyblue')

      switch (data?.type) {
        case 'CRASH_BET':
          setPlayerBetList(data.data)
          break
        case 'CASHED_OUT':
          setPlayerOutList(data.data)
          break
        case 'END_RESULT':
          useCrash.getState().setPlayerEndResult(data.data)
          setGameEvents('3')
          setPlayerBetStatus('END_RESULT')

          if (useCrash.getState().isWaitingForRestfulApiResult) {
            useCrash.getState().setIsWaitingForRestfulApiResult(false)
          }

          // KIBANA Logs
          if (useLogStore.getState().isWaitingForBetApiResult === true) {
            // 埋log KIBANA Bet ApiResult
            const frontEndLog = {
              timestamp: new Date().toISOString(),
              crashed: {
                planHashId: useCrash.getState().gameStatus.data.planHashId,
                userInfo: useAuth.getState().userInfo,
                betData: useCrash.getState().betData,
                errorMessages: useGlobal.getState().errorMessagesLog,
                device: navigator.userAgent,
                command: '沒有收到BetApi的回應'
              }
            }
            sendLog(frontEndLog)
          }
          if (useLogStore.getState().isWaitingForCashedOutApiResult === true) {
            // 埋log KIBANA Cashout ApiResult
            const frontEndLog = {
              timestamp: new Date().toISOString(),
              crashed: {
                planHashId: useCrash.getState().gameStatus.data.planHashId,
                userInfo: useAuth.getState().userInfo,
                betData: useCrash.getState().outData,
                errorMessages: useGlobal.getState().errorMessagesLog,
                device: navigator.userAgent,
                command: '沒有收到CashedOutApi的回應'
              }
            }
            sendLog(frontEndLog)
          }
          useLogStore.setState({ isWaitingForBetApiResult: false, isWaitingForCashedOutApiResult: false })
      }
    })

    //輪次遊戲event
    clientSocket.current.on('CRASH_GAME_EVENT', async function (data) {
      useInitial.setState({ SocketIO: true })

      if (CONSOLE_SHOW.CRASH_GAME_EVENT) {
        console.log(`%c[${data?.type}]`, 'color: yellow', dayjs(Date.now()).format('HH:mm:ss'))
      }

      // 遊戲狀態機寫入
      // console.log('遊戲狀態機', data)
      if (data?.type) setGameStatus(data.type, data.data, dayjs(data.meta).format('yyyy-mm-dd'))

      switch (data?.type) {
        case 'START_BETTING':
          //   console.log('useGlobal.getState().gameEvents', useGlobal.getState().gameEvents)
          setGameEvents('0')

          break

        //INIT
        case 'BETTING':
          setGameEvents('INIT 0')
          break

        case 'START_DRAWING':
          setGameEvents('1')
          break

        // INIT
        case 'DRAWING':
          setGameEvents('INIT 1')
          break

        case 'END_DRAWING':
          setGameEvents('2')
          setTimeout(() => {
            useLogStore.getState().resetAmounts()
          }, 1000)
          break
      }
    })

    //USER_INFO
    clientSocket.current.on('USER_INFO', function (data) {
      console.error('USER_INFO SOCKET 版本應該不會再顯示')
    })

    setSocket(clientSocket.current)
  }

  const ioReconnect = () => {
    // Early return
    if (useSocket.getState().reconnectTimes > 2) return //2次以上返回

    // 控制重新連線需要初始化data, 並增加reconnectTime次數 當次數大於5的時候停止reconnect. 顯示ModalReconnect
    console.log('%c[IO] SocketIo Reconnecting... ', 'color:yellow')
    initRoundData()
    initRoundList()

    //第一次要馬上打所以不能用setTimeout
    if (useSocket.getState().reconnectTimes === 0) {
      clientSocket.current.io.open()
      useSocket.getState().setReconnectTimes(true)
      console.log('斷線重打次數', useSocket.getState().reconnectTimes)
    }

    setTimeout(() => {
      useSocket.getState().setReconnectTimes(true) //打了加一次
      if (useSocket.getState().reconnectTimes <= 2) {
        clientSocket.current.io.open((err) => {
          if (err) {
            ioReconnect()
            console.log('斷線重打次數', useSocket.getState().reconnectTimes)
          }
        })
      } else if (useSocket.getState().reconnectTimes > 2) return
    }, 10000) //每10秒打一次
  }
}

export default useBettingSocket

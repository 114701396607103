import { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/ui'
import dayjs from 'dayjs'
import { crashPoint as api } from '@/api/restful/crashpoint'

export const GameRecordTable = ({ gameData, handleModalClose }) => {
  const { t } = useTranslation()
  const [betList, setBetList] = useState([])
  const iconsMap = ['svg/star1.svg', 'svg/star2.svg', 'svg/star3.svg']

  useEffect(() => {
    fetchAndSetData()
  }, [gameData])

  const fetchAndSetData = async () => {
    const apiResult = (await api.getBetListById(gameData.planHashId)).data
    const data = _.chain(apiResult)
      .filter((item) => item.status === 'win')
      .map((item) => ({ ...item, winAmount: Number(item.winAmount)?.toFixed(2) }))
      .sort((a, b) => b.winAmount - a.winAmount)
      .value()
    setBetList(data)
  }

  const modalCallBack = () => {
    handleModalClose()
  }

  return (
    <Modal idName="game-record" zIndex="2060" title={t('gameRecord')} closeCallback={modalCallBack}>
      <section className="p-2">
        <h2 className="text-white text-lg font-bold mb-4">Crash Game</h2>
        <ul>
          <li>
            <span className="text-white mr-1">Id：</span>
            <span>{gameData.planHashId}</span>
          </li>
          <li>
            <span className="text-white mr-1">{t('numberBets')}：</span>
            <span>{betList?.length}</span>
          </li>
          <li>
            <span className="text-white mr-1">{t('StartAt')}：</span>
            <span>{dayjs(+gameData.startDrawing).format('YYYY-MM-DD HH:mm:ss')}</span>
          </li>
          <li>
            <span className="text-white mr-1">{t('betController.multiplier')}：</span>
            <span>{Number(gameData.crashPoint)?.toFixed(2)}x</span>
          </li>
        </ul>
      </section>
      <button className="p-2 text-white">{t('verifyBet')}</button>
      <section>
        <table className="table-bet">
          <thead>
            <tr className="filled">
              <th>{t('betController.user')}</th>
              <th>{t('betController.payout')}</th>
            </tr>
          </thead>
          <tbody>
            {betList?.map((bet, index) => {
              return (
                <tr key={index}>
                  {index < 3 && (
                    <td>
                      <article className="flex flex-row items-center">
                        <div className="relative inline-block">
                          <span className="absolute w-[20px] h-[20px] font-bold text-white text-center top-0.5 shadow-text">{index + 1}</span>
                          <img className="mr-1 w-[20px] h-[20px]" loading="lazy" src={iconsMap[index]}></img>
                        </div>
                        <div className="text-white inline-block">{bet?.userDisplayName ? bet.userDisplayName.split('#')[1] : '-'}</div>
                      </article>
                    </td>
                  )}
                  {index >= 3 && (
                    <td>
                      <div className="relative inline-block">
                        <span className="absolute w-[20px] h-[20px] font-bold text-white text-center top-0.5 shadow-text">{index + 1}</span>
                        <img className="mr-1 w-[20px] h-[20px]" loading="lazy" src="svg/star-normal.svg"></img>
                      </div>
                      <div className="text-white inline-block">{bet.userDisplayName?.split('#')[1] ? bet.userDisplayName.split('#')[1] || bet.username : '-'}</div>
                    </td>
                  )}
                  <td>{bet.winAmount}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </section>
    </Modal>
  )
}

export default GameRecordTable

import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/ui'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { crashPoint as api } from '@/api/restful/crashpoint'
import { isEmpty } from 'ramda'
import { AiOutlineLoading3Quarters as IconLoading } from 'react-icons/ai'
import { removeDuplicatesObjByKey } from '@/utils'
import dayjs from 'dayjs'

export const DrawListTable = ({ handleModalClose, handleShowGameRecord }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [tableListData, setTableListData] = useState([])
  const [drawItemNumber] = useState(100)

  const listRef = useRef(null)
  const currentPage = useRef(null)

  // Ready
  useEffect(() => {
    currentPage.current = 1
    document.querySelector('body').style.position = 'fixed'
    if (isEmpty(tableListData)) getFirstData()
    listRef.current = document.querySelector('#History .modal-content')
    listRef.current.addEventListener('scroll', debounce(scrollToBottom, 400))

    return () => {
      setTableListData([])
      currentPage.current = 1
      setIsLoading(false)
      document.querySelector('body').style.position = ''
      listRef.current.removeEventListener('scroll', debounce(scrollToBottom, 400))
    }
  }, [])

  // 第一次取資料
  const getFirstData = async () => {
    setIsLoading(true)
    const apiResult = await api.getDrawListByPage(1, 30)
    if (isEmpty(apiResult)) return
    setTableListData(apiResult.data.items)
    setIsLoading(false)
  }

  // 滑到底取資料
  const scrollToBottom = async () => {
    if (isLoading) return
    const scrollTop = listRef.current.scrollTop
    const clientHeight = listRef.current.clientHeight
    const scrollHeight = listRef.current.scrollHeight

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setIsLoading(true)
      const apiResult = await api.getDrawListByPage(currentPage.current + 1, drawItemNumber)
      if (isEmpty(apiResult)) return
      setTableListData((oldData) => removeDuplicatesObjByKey([...oldData, ...apiResult.data.items], 'planHashId').sort((a, b) => b.planHashId - a.planHashId))
      setIsLoading(false)
      currentPage.current += 1
    }
  }

  // 開啟這個場次的詳細資料
  const handleReturnGameDataAndShow = (game) => {
    handleShowGameRecord(game)
  }

  // 關閉視窗
  const modalCallBack = () => {
    handleModalClose()
  }

  return (
    <Modal idName="History" zIndex="2060" title={t('history')} closeCallback={modalCallBack}>
      <main id="draw-list-table" className="flex flex-col justify-between">
        <table className="table-bet">
          <thead>
            <tr className="filled">
              <th>{t('time')}</th>
              <th>{t('betController.multiplier')}</th>
              <th>{t('ui.details')}</th>
            </tr>
          </thead>
          <tbody>
            {tableListData.length > 0 &&
              tableListData.map((game, index) => {
                return (
                  <tr key={`draw-list-${game.planHashId}`}>
                    <td>{dayjs(+game.startDrawing).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>
                      <span className={clsx('text-center', game.crashPoint >= 2 ? 'win' : '')}>{Number(game.crashPoint)?.toFixed(2)}x</span>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          handleReturnGameDataAndShow(game)
                        }}>
                        {t('ui.view')}
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        <div className="h-[50px] mb-[80px] flex justify-center items-center text-center">{isLoading && <IconLoading className="animate-spin mx-auto" />}</div>
      </main>
    </Modal>
  )
}

export default DrawListTable

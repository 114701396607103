import { init } from '@tcg/frontend-log'
import packageJson from '../../package.json'
export const initKibana = () => {
  const initObj = {
    merchant: 'merchant',
    version: packageJson.version,
    environment: process.env.VITE_ENV,
    service_name: packageJson.name
  }
  init(initObj)
}
